/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-27",
    versionChannel: "nightly",
    buildDate: "2025-01-27T00:06:15.922Z",
    commitHash: "9327d74577158fc7036508d888218d44341aa25a",
};
